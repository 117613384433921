<template>
  <div class="card">
    <div class="card-body" v-if="!toggle">
      <span class="card-text">
        <span
          class="mx-1 pointer float-right"
          @click="toggle = !toggle">
          <fa icon="pencil-alt" class="text-warning"></fa>
        </span>
        <div class="">
          {{item.stockAsset.code}}
        </div>
        <div
          class="text-info"
          v-for="child in item.junctionChildren.filter(x => x.type === 'itemAccount')"
          :key="child.id">
          <small>กลุ่มบัญชีค่าเสื่อมสะสม: {{child.name}}</small>
        </div>
        <div
          class="text-warning"
          v-for="child in item.junctionChildren.filter(x => x.type === 'expenseAccount')"
          :key="child.id">
          <small>กลุ่มบัญชีค่าเสื่อม: {{child.name}}</small>
        </div>
      </span>
    </div>

    <div class="card-body" v-if="toggle">
      <div class="card-text">
        <div class="form-row">
          <DocConfigFormStockAsset
            :templateType="templateType"
            :docType="docType"
            ref="stockAsset"
            class="col-12"
            select="id"
            v-model="formData.stockAssetId"
            :validations="[
              {text: 'required!', value: $v.formData.stockAssetId.$dirty && !$v.formData.stockAssetId.required}
            ]">
          </DocConfigFormStockAsset>

          <DocConfigFormItemAccount
            label="กลุ่มบัญชีค่าเสื่อมสะสม"
            placeholder="ค่าเริ่มต้น..."
            :templateType="templateType"
            :docType="docType"
            class="col-12"
            select="id"
            v-model="formData.itemAccountId"
            :validations="[
              {text: 'required!', value: $v.formData.itemAccountId.$dirty && !$v.formData.itemAccountId.required}
            ]">
          </DocConfigFormItemAccount>

          <DocConfigFormExpenseAccount
            label="กลุ่มบัญชีค่าเสื่อม"
            placeholder="ค่าเริ่มต้น..."
            :templateType="templateType"
            :docType="docType"
            class="col-12"
            select="id"
            v-model="formData.expenseAccountId"
            :validations="[
              {text: 'required!', value: $v.formData.expenseAccountId.$dirty && !$v.formData.expenseAccountId.required}
            ]">
          </DocConfigFormExpenseAccount>
        </div>

        <button
          v-if="isForm"
          type="button"
          class="btn btn-success"
          @click="updateData">
          เพิ่ม
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-warning"
          @click="updateData">
          แก้ไข
        </button>

        <button
          v-if="toggle"
          type="button"
          class="btn btn-primary ml-2"
          @click="cancelData">
          ยกเลิก
        </button>

        <button
          v-if="!isForm"
          type="button"
          class="btn btn-danger ml-2 float-right"
          @click="destroyData(item.id)">
          ลบ
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DocConfigFormStockAsset from '@/views/doc_core/components/DocConfigFormStockAsset'
import DocConfigFormItemAccount from './DocConfigFormItemAccount'
import DocConfigFormExpenseAccount from './DocConfigFormExpenseAccount'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    isForm: {
      type: Boolean,
      required: false
    },
    menu: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    stockAsset: {
      type: Object,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        type: this.type,
        stockAssetId: null,
        itemAccountId: null,
        expenseAccountId: null,
      }
    }
  },
  validations: {
    formData: {
      stockAssetId: {required},
      itemAccountId: {required},
      expenseAccountId: {required},
    }
  },
  methods: {
    serializeInput (formData) {
      const obj = {
        ...formData,
        junctionChildren: []
      }
      delete obj.itemAccountId
      delete obj.expenseAccountId

      if (formData.itemAccountId) {
        obj.junctionChildren.push({
          tag: 'itemAccountId',
          childId: formData.itemAccountId
        })
      }
      if (formData.expenseAccountId) {
        obj.junctionChildren.push({
          tag: 'expenseAccountId',
          childId: formData.expenseAccountId
        })
      }

      return obj
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$emit('update', {
        id: this.item.id,
        input,
        cb: () => {
          this.toggle = false
          this.$v.formData.$reset()
        }
      })
    },
    destroyData (id, parentId) {
      this.$emit('destroy', {id, parentId})
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          if (this.item.stockAsset) {
            this.formData.stockAssetId = this.item.stockAsset.id
            this.$refs.stockAsset.setGroup(this.item.stockAsset.type)
          }

          if (this.item.junctionChildren.length === 0) return

          const itemAccount = this.item.junctionChildren.find(v => v.type === 'itemAccount')
          const expenseAccount = this.item.junctionChildren.find(v => v.type === 'expenseAccount')

          if (itemAccount) this.formData.itemAccountId = itemAccount.id
          if (expenseAccount) this.formData.expenseAccountId = expenseAccount.id
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {
          if (this.stockAsset) {
            this.formData.stockAssetId = this.stockAsset.id
            this.$refs.stockAsset.setGroup(this.stockAsset.type)
          }
        })
      } else {
        this.formData.stockAssetId = null
        this.formData.itemAccountId = null
        this.formData.expenseAccountId = null
      }
    },
  },
  created () {
    if (this.isForm) {
      this.toggle = true
    }
  },
  components: {
    DocConfigFormStockAsset,
    DocConfigFormItemAccount,
    DocConfigFormExpenseAccount
  }
}
</script>

<style lang="css" scoped>
</style>
