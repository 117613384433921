import gql from 'graphql-tag'


const detailResponse = `
  id type name parentId
  qty price totalPrice
  unitId unit {id name}
  children {
    id type name parentId
    qty price totalPrice
    unitId unit {id name}
    stocks {
      id qty docId parentId orderId isPrinted
      inventoryId inventory {
        id code name
        unitId unit {id name}
      }
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    ledgers {
      id docId stockId orderId amount
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  order: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id docId}
}`

export const CREATE_ORDER_ITEM = (templateType) => gql`mutation CREATE_ORDER_ITEM ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderItemInput!) {
  createOrder: createDoc${templateType}OrderItem (docType: $docType, docId: $docId, input: $input) {id}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {
    id docId
  }
}`


export const LIST_ORDER_ITEM = (templateType) => gql`query LIST_ORDER_ITEM ($docType: String!, $q: FilterInput) {
  items: listDoc${templateType}OrderItem (docType: $docType, q: $q) {
    id type
    stockAssetId stockAsset {id code}
  }
}`

export const DETAIL_ORDER_ITEM_DEDUCTION_RATE = (templateType) => gql`query DETAIL_ORDER_ITEM_DEDUCTION_RATE ($docType: String!, $docConfigId: Int!) {
  deductionRate: detailDoc${templateType}OrderItemDeductionRate (docType: $docType, docConfigId: $docConfigId) {
    amountRemaining amountRate excessRate
  }
}`
